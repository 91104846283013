import React, { useState, useEffect } from "react"

const InvoiceItem = ({ item, updateItem, deleteItem }) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const [price, setPrice] = useState(item.price)
  const [label, setLabel] = useState(item.description)

  useEffect(() => {
    const updatedItem = { ...item, quantity: quantity || 0, price: price || 0, description: label }
    updateItem(updatedItem)
  }, [price, label, quantity])

  const updatePrice = e => {
    setPrice(e.target.value)
  }

  const updateQuantity = e => {
    setQuantity(e.target.value)
  }

  const updateLabel = e => {
    setLabel(e.target.value)
  }

  const updateInternalPrice = e => {
    const internalPrice = parseFloat(e.target.value)
    updateItem({ ...item, internalPrice: internalPrice })
  }

  const hasItemLink = item.itemLink === undefined
  const hasInternalPrice = item.internalPrice !== undefined

  return (
    <div className={`tblRow ${item.index}`} key={item.index}>
      <div className="w45 itemNameCol">
        {hasItemLink && (
          <>
            <input
              type="text"
              name="description[]"
              className="form-control itemName"
              placeholder="Enter item name / memo"
              onChange={updateLabel}
              value={label}
            />
            <button
              className="btn btn-check btn-round btn-primary btnInsertProd"
              data-bs-toggle="tooltip"
              title="Insert Product"
            >
              <i className="bi bi-check2"></i>
            </button>
          </>
        )}
        {!hasItemLink && (
          <a href={item.itemLink} target="_blank">
            {item.label}
          </a>
        )}
      </div>
      <div className="w12 qtyCol">
        <input
          type="number"
          name="quantity[]"
          className="form-control itemQty text-end"
          min="0"
          value={quantity}
          onChange={updateQuantity}
        />
      </div>
      <div className="w12 itemPriceCol">
        {hasItemLink && (
          <>
            <input
              type="number"
              name="price[]"
              className="form-control itemPrice"
              min="0"
              value={price}
              onChange={updatePrice}
            />
          </>
        )}
        {!hasItemLink && (
          <input
            type="text"
            name="price[]"
            className="form-control itemPrice"
            readOnly={true}
            value={price}
            onChange={updatePrice}
          />
        )}
        <input type="hidden" name="internal_cost[]" className="form-control itemInternalPrice" />
      </div>
      <div className="w15 itemPriceCol">
        {hasInternalPrice && (
          <input
            type="number"
            name="internalprice[]"
            min="0"
            onChange={updateInternalPrice}
            step=".01"
            value={item.internalPrice}
            className="form-control itemInternalPriceMain internalCostInfo"
          />
        )}
      </div>
      <div className="w12 colSubTotal text-end">
        {item.recurring && (
          <i className="bi bi-arrow-repeat" data-bs-toggle="tooltip" title="Recurring Billing"></i>
        )}
        ${(price * quantity).toFixed(2)}
      </div>
      <div className="w4 actionCol">
        <button
          className="btn btn-opt-trash btn-round"
          data-bs-toggle="tooltip"
          title="Delete this row"
          onClick={() => deleteItem(item)}
        >
          <i className="bi bi-trash-fill"></i>
        </button>
      </div>
    </div>
  )
}

export default InvoiceItem
