import React, { useState, useEffect } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { getFullName, getProfileImage } from "../../../services/settingsHelper"

const InvoiceModal = prop => {
  const [title, setTitle] = useState(""),
    [type, setType] = useState("invoice"),
    [invoiceStatus, setInvoiceStatus] = useState("due")

  const saveInvoice = async () => {
    const invoiceData = {
      invoiceName: title,
      invoiceStatus,
      invoiceType: type,
      parentID: prop?.prop ?? 0,
      ownerID: localStorage.getItem("user_id"),
      content: {
        items: [],
        payment: 0,
        discount: 0,
        shipping: 0,
        installation: 0,
        account: 0,
        payMethods: [],
        payee: {
          name: getFullName(),
          id: localStorage.getItem("user_id"),
          profilePic: getProfileImage(),
          email: localStorage.getItem("user_email"),
        },
      },
    }

    try {
      const createInvoiceResponse = await AxiosInstance.post("/drive/invoice/", invoiceData)
      if (createInvoiceResponse.status == 201) {
        window.location.href = `/drive/invoice/${createInvoiceResponse.data.driveInvoice.fileUrl}`
        return
      }
      console.log("Unexpected response status code: ", createInvoiceResponse.status)
    } catch (error) {
      console.log("Error when creating invoice: ", error.message)
    }
  }

  const updateType = e => {
    setType(e.target.value)
  }

  const updateStatus = e => {
    setInvoiceStatus(e.target.value)
  }

  const updateTitle = e => {
    setTitle(e.target.value)
  }

  return (
    <div
      className="modal fade"
      id="newInvoiceModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="newInvoiceModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="newInvoiceModalTitle">
              <i className="bi bi-list-ul" /> Estimate / Invoice / Receipt / Etc.
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="mb-3 row align-items-center">
              <label htmlFor="invoiceName" className="col-sm-4 col-form-label">
                Title
                <span
                  data-toggle="tooltip"
                  title="A descriptive name so you know what it's for. Will display to all parties."
                >
                  {" "}
                  <i className="bi bi-question-circle-fill" />
                </span>
              </label>
              <div className="col-sm-8">
                <input
                  name="invoiceName"
                  id="invoiceName"
                  placeholder="Name"
                  className="form-control"
                  type="text"
                  value={title}
                  onChange={updateTitle}
                />
              </div>
            </div>
            <div className="mb-3 row align-items-center">
              <label htmlFor="invoiceTypeOpts" className="col-sm-4 col-form-label">
                Status
              </label>
              <div className="col-sm-8">
                <div className="input-group">
                  <select
                    className="form-select mb-1"
                    name="invoiceType"
                    id="invoiceTypeOpts"
                    value={type}
                    onChange={updateType}
                  >
                    <option value="invoice">Invoice</option>
                    <option value="estimate">Estimate</option>
                    <option value="proposal">Proposal</option>
                    <option value="quote">Quote</option>
                    <option value="purchase_order">Purchase Order</option>
                    <option value="credit_memo">Credit Memo</option>
                    <option value="receipt">Receipt</option>
                  </select>
                  <select
                    className="form-select mb-1"
                    name="invoiceStatus"
                    id="invoice_status_opts"
                    value={invoiceStatus}
                    onChange={updateStatus}
                  >
                    <option value="not_yet_committed">Not Yet Committed</option>
                    <option value="committed">Committed</option>
                    <option value="due">Due</option>
                    <option value="paid_partial">Paid Partial</option>
                    <option value="paid">PAID</option>
                    <option value="canceled">Canceled</option>
                    <option value="refunded">Refunded</option>
                    <option value="unpaid_overdue">Unpaid Overdue</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-bs-dismiss="modal">
              <i className="bi bi-x-lg" /> Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={saveInvoice}>
              <i className="bi bi-check-lg" /> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceModal
